import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

// import { Badge } from 'reactstrap';
import img1 from "../images/anon-1.png";
import img2 from "../images/anon-2.png";
import img3 from "../images/anon-3.png";

const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - Design System"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `application`, `react`, `nextjs`]}
    />
    <div className="portfolio-header">
      <h1>Data Discovery Platform</h1>
      <h5>Product Design</h5>
      <p>UI Design for a Data Discovery platform that allows organisations to cross data in a private and anonymous way.</p>
      <p>Built with Next.js, Antd, Styled-components and Highcharts.</p>
    </div>
    {/* <div className="portfolio-footer">
      <a className="btn btn-primary" href="https://wushka.com.au" target="_blank" rel="noopener noreferrer">View Project</a>
    </div> */}
    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">Login Screen</figcaption>
        <img src={img1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Connected Organisations Page - Summary</figcaption>
        <img src={img2} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Connected Organisations Page - Insights</figcaption>
        <img src={img3} alt=""/>
      </figure>
    </div>

  </Layout>
)

export default PortfolioPage
